import { put, takeLatest, all, call, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as changelogDataActionCreators from '../store/actions/changelogDataActions';
import axios from '../axios';
import { regionTypeEnums } from '../constants/regionTypes';



export function* getChangelogResourceTags() {
    const response = yield axios.get('api/tags/changelogResource');
    yield put(changelogDataActionCreators.getChangelogResourceTagsFinished(response.data.map((tag) => {
        return {
            label: tag.value,
            value: tag.id
        }
    })));
}
export function* getChangelogTags() {
    const response = yield axios.get('api/tags/changelog');
    yield put(changelogDataActionCreators.getChangelogTagsFinished(response.data.map((tag) => {
        return {
            label: tag.value,
            value: tag.id
        }
    })));
}
export function* getChangelog(action) {
    const url = `api/changelogs/${action.payload}`;
    const resources = url + "/resources";
    const response = yield all([
        call(axios.get, url),
        call(axios.get, resources)
    ]);
    const changelog = response[0];
    changelog.data.resources = response[1].data;
    yield put(changelogDataActionCreators.getChangelogFinished(changelog.data));
}

export function* getActivities(action, baseId) {
    try {
        const activities = yield axios.get('api/wbs/activities', { params: { ...action.payload, pageable: false, regionType: regionTypeEnums.Central } });
        //if there is base id check if activities contains that item. If not try to match it
        if (baseId) {
            let activity = activities.data.items.find((activity) => {
                return activity.id === baseId
            });
            if (!activity) {
                const res = yield axios.get(`api/activities/${baseId}`);
                activity = res.data;
                //if activity match selected activity type push it to array
                if (parseInt(activity.activityType) === parseInt(action.payload.activityType) && (parseInt(activity.subDomainId) === parseInt(action.payload.subDomainId) || activity.subDomainId === null)) {
                    activities.data.items.push(activity);
                }
            }
        }
        yield put(changelogDataActionCreators.getActivitiesFinished(activities.data.items))
    } catch (error) {
        yield put(changelogDataActionCreators.getActivitiesFailed());
    }
}


export function* getWbsesByActivity(action) {
    try {
        const params = action.payload.params;
        const wbsBaseId = action.payload.wbsBaseId;
        params.withDeleted = true;
        params.pageable = false;
        const wbses = yield axios.get('api/wbs', action.payload);
        wbses.data.items = wbses.data.items.filter((item) => {
            return !item.isDeleted || item.id === wbsBaseId;
        })
        yield put(changelogDataActionCreators.getPoWbsesByActivityFinished(wbses.data.items));
    } catch (error) {
        yield put(changelogDataActionCreators.getPoWbsesByActivityFailed());
    }
}

export function* getVendorTypesByDomain(action) {
    try {
        const vendorTypes = yield axios.get('api/vendorTypes/' + action.payload);
        yield put(changelogDataActionCreators.getVendorTypesByDomainFinished(vendorTypes.data))
    } catch (error) {
        yield put(changelogDataActionCreators.getVendorTypesByDomainFailed())
    }
}

export function* getVendorsByVendorType(action, baseId) {
    try {
        const params = action.payload;
        params.pageable = false;
        const vendors = yield axios.get('api/vendors', { params: action.payload });
        //if there is base id check if vendors contains that item. If not try to match it
        if (baseId) {
            let vendor = vendors.data.items.find((vendor) => {
                return vendor.id === baseId
            });
            if (!vendor) {
                const res = yield axios.get(`api/vendors/${baseId}`);
                vendor = res.data
                if (vendor.name !== "PMI" && !vendors.data.items.find((vendor) => vendor.name === "PMI")) {
                    vendors.data.items.push(vendor);
                }
            }
        }
        yield put(changelogDataActionCreators.getVendorsByVendorTypeFinished(vendors.data.items))
    } catch (error) {
        yield put(changelogDataActionCreators.getVendorsByVendorTypeFailed())
    }
}

export function* getVendorRatesByVendor(action) {
    try {
        const vendorTypes = yield axios.get('api/vendorRates/vendor/' + action.payload);
        yield put(changelogDataActionCreators.getVendorRatesByVendorFinished(vendorTypes.data))
    } catch (error) {
        yield put(changelogDataActionCreators.getVendorRatesByVendorFailed())
    }
}

export function* getResourceTypesByDomainAndVendorType(action) {
    try {
        const resourceTypes = yield axios.get('api/resourceTypes', { params: action.payload });
        yield put(changelogDataActionCreators.getResourceTypesByDomainAndVendorTypeFinished(resourceTypes.data));
    } catch (error) {
        yield put(changelogDataActionCreators.getResourceTypesByDomainAndVendorTypeFailed());
    }
}

export function* getSubDomainsByDomain(action, optionalId) {
    try {
        const subDomains = yield axios.get('api/domains/' + action.payload + "/subdomains");
        let subDomain = subDomains.data.find((subDomain) => {
            return subDomain.id === optionalId;
        })
        if (!subDomain && optionalId) {
            subDomain = axios.get('api/Subdomains/' + optionalId);
            const newSubDomains = [...subDomains];
            newSubDomains.push(subDomain.data);
            yield put(changelogDataActionCreators.getSubDomainsByDomainFinished(newSubDomains));
        } else {
            yield put(changelogDataActionCreators.getSubDomainsByDomainFinished(subDomains.data));
        }
    } catch (error) {
        yield put(changelogDataActionCreators.getSubDomainsByDomainFailed());
    }
}

export default [
    takeLatest(actionTypes.GET_CHANGELOG_STARTED, getChangelog),
    takeLatest(actionTypes.GET_ACTIVITIES_STARTED, getActivities),
    takeLatest(actionTypes.GET_PO_WBSES_BY_ACTIVITY_STARTED, getWbsesByActivity),
    takeLatest(actionTypes.GET_VENDOR_TYPES_BY_DOMAIN_STARTED, getVendorTypesByDomain),
    takeLatest(actionTypes.GET_VENDORS_BY_VENDOR_TYPE_STARTED, getVendorsByVendorType),
    takeLatest(actionTypes.GET_VENDOR_RATES_BY_VENDOR_STARTED, getVendorRatesByVendor),
    takeLatest(actionTypes.GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_STARTED, getResourceTypesByDomainAndVendorType),
    takeLatest(actionTypes.GET_SUBDOMAINS_BY_DOMAIN_STARTED, getSubDomainsByDomain)
];